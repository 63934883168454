body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  -webkit-text-size-adjust: 100%;
}

td,
th,
caption {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-weight: normal;
  font-size: 100%;
}

// address,
// caption,
// cite,
// code,
// dfn,
// em,
// strong,
// th,
// var {
//   font-style: normal;
//   font-weight: normal;
// }

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  border: none;
}

ol,
ul,
li {
  list-style: none;
}

table {
  border-collapse: collapse;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix {
  *zoom: 1;
}

.Flex {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
}

* {
  box-sizing: border-box;
}

@mixin ellipsis($line-count) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  /* 显示的行数 */
  -webkit-box-orient: vertical;
  white-space: normal;
}
