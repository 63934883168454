.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(84, 111, 255, 1);
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  color: rgba(84, 111, 255, 1);
  font-weight: 600;
  background-color: rgba(169, 169, 172, 0.1);
}

.ant-tree .ant-tree-treenode-selected{
  background-color: #f0f5ff;
  .ant-tree-indent{
    background-color: #fff;
  }
}

.ant-tree .ant-tree-treenode{
  padding: 0;
  .ant-tree-indent{
    padding-bottom: 0;
  }
  .ant-tree-node-content-wrapper{
    padding: 0 0 4px 0;
  }
}
.ant-tree .ant-tree-treenode:hover{
  background-color: rgba(0, 0, 0, 0.04);
  .ant-tree-indent{
    background-color: #fff;
  }
}

.ant-tree .ant-tree-node-content-wrapper:hover, .ant-tree .ant-tree-checkbox+span:hover{
  background-color: transparent;
}

.ant-tree.draggable-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon{
  display: block;
  opacity: 0;
}

.ant-tree.draggable-tree .ant-tree-treenode-draggable:hover .ant-tree-draggable-icon{
  opacity: 0.3;
  cursor: pointer;
}